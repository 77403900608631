import { Box, Chip, Stack } from '@mui/material'
import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { routes } from 'services'
import { AppRoles, MissionStatus } from 'types'
import { useAppDispatch, useAppSelector, usePageRoles } from 'utils'
import { getAffectedMissionsRD, getInProgressMissionsRD } from 'reducers/rd/thunks'
import { ArrowBackIosNew } from '@mui/icons-material'
import './MissionsList.scss'

const chipColor = (statut: string) => {
  switch (statut) {
    case MissionStatus.validated:
      return 'success'
    case MissionStatus.deleted:
    case MissionStatus.canceled:
      return 'error'
    default:
      return 'primary'
  }
}

export default function MissionsList() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const missionList = useParams<{statut: string}>()
  usePageRoles([AppRoles.RD])
  const { activeRole } = useAppSelector(state => state.app)
  const { missionsRD, inProgressMissionsRD } = useAppSelector(state => state.rd)

  useEffect(() => {
    if (activeRole && activeRole.role !== AppRoles.admin) {
      dispatch(getAffectedMissionsRD(activeRole.technicenterId))
      dispatch(getInProgressMissionsRD(activeRole.technicenterId))
    }
  }, [activeRole])

  const getFilteredList = () => {
    if (missionList.statut === 'affectee') {
      return missionsRD.filter(mission => mission.statut === 'AFFECTEE')
    } if (missionList.statut === 'supprimee' || missionList.statut === 'refusee') {
      return missionsRD.filter(mission => mission.statut === 'SUPPRIMEE' || mission.statut === 'REFUSEE')
    } if (missionList.statut === 'validee') {
      return missionsRD.filter(mission => mission.statut === 'VALIDEE')
    }
    return inProgressMissionsRD
  }

  return (
    <div className="rd-missions-list">
      <button
        type="button"
        className="back-button"
        onClick={() => {
          navigate(routes.rd)
        }}
      >
        <ArrowBackIosNew />
        Retour au menu
      </button>
      <div className="list">
        {getFilteredList().map(mission => (
          <button
            type="button"
            className="mission"
            key={mission.id}
            onClick={() => {
              navigate(
                `${routes.rd}${routes.rdMissionsList}/${missionList.statut}/${mission.id}`,
              )
            }}
          >
            <Box>
              <Chip
                sx={{ height: '60px', width: '60px', fontSize: 18 }}
                label={mission.numero}
                style={{ borderRadius: 4 }}
                color={chipColor(mission.statut)}
              />
            </Box>
            <Stack alignItems="flex-start" fontSize={16}>
              <Box>
                {mission.modele.libelle}
              </Box>
              <Box>
                {mission.rame}
                {' '}
                {mission.um ? `(${mission.um_details} UM)` : ''}
              </Box>
              <Box>
                {mission?.voie_depart?.libelle}
                {mission.voie_arrivee ? ' > ' : ''}
                {mission?.voie_arrivee?.libelle}
              </Box>
            </Stack>
          </button>
        ))}
      </div>
    </div>
  )
}
