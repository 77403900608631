import {
  Chip, Table, TableBody, TableCell, TableHead, TableRow,
  Tooltip,
} from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import * as moment from 'moment'
import {
  Checkbox, DayGapBadge, Loader, ModalAddComment, ModalConfirm, ModalPatchMissionNPD, ModalPatchMissionSelectField,
  ModalPatchMissionTCHour, modalSignal,
} from 'components'
import { useAppDispatch, useAppSelector, useCerbereUsers } from 'utils'
import { AppRoles, MissionStatus, MissionTC, ParamsDefects } from 'types'

import {
  handleMissionTCNPD, handleMissionTCPE, handlePatchAdditionnalMissionsTC, handlePatchMissionTC, handleStartMissionTC,
} from 'services'
import { Check, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { getMissionsTC } from 'reducers/missionTable/thunks'
import MissionActions from './actions/MissionActions'
import './MissionTable.scss'
import { missionListSignal } from '../header/Header'
import ActionWrapper from './actionWrapper/ActionWrapper'
import MissionOrientation from './orientation/MissionOrientation'

const Headers = [
  { id: 'id', label: 'ID', ordering: 'numero' },
  { id: 'rame', label: 'Rame', ordering: 'rame' },
  { id: 'orientation', label: 'Orientation', ordering: 'sens_depart__libelle' },
  { id: 'mission', label: 'Mission', ordering: 'modele__libelle' },
  { id: 'chantier', label: 'Chantier', ordering: 'chantier_depart__libelle' },
  { id: 'voie_depart', label: 'Voie départ', ordering: 'voie_depart__libelle' },
  { id: 'voie_arrivee', label: 'Voie arrivée', ordering: 'voie_arrivee__libelle' },
  { id: 'heure_debut_theorique', label: 'Heure début théo.', ordering: 'heure_debut_theorique' },
  { id: 'heure_fin_theorique', label: 'Heure fin théo.', ordering: 'heure_fin_theorique' },
  { id: 'agent', label: 'Agent', ordering: null },
  { id: 'sens_depart', label: 'Sens de départ', ordering: 'sens_depart__libelle' },
  { id: 'via', label: 'Via', ordering: 'via' },
  { id: 'mission_supplementaire', label: 'Mission(s) supplémentaire(s)', ordering: null },
  { id: 'observation_com', label: 'Observation COM', ordering: 'observation_com' },
  { id: 'pancartage', label: 'Pancartage', ordering: 'pancartage' },
  { id: 'mission_prise_en_compte', label: 'Mission prise en compte', ordering: 'prise_en_compte' },
  { id: 'presence_sur_engin', label: 'Présence sur engin', ordering: 'presence_sur_engin' },
  { id: 'ne_pas_deranger', label: 'Essais et conduite', ordering: 'debut_npd' },
  { id: 'defaut_constate', label: 'Défaut(s) constaté(s)', ordering: null },
  { id: 'observation_rd', label: 'Observation RD', ordering: null },
  { id: 'statut', label: 'Statut', ordering: 'statut' },
  { id: 'action', label: 'Action', ordering: null },
]

interface Props {
  technicalCenterId: string
  selectedMissions: string[]
  setSelectedMissions: (missions: string[]) => void
  missionsOrdering: string
  setMissionsOrdering: (ordering: string) => void
}

const rowBGColor: { [key in MissionStatus]: string } = {
  [MissionStatus.assigned]: '#fff',
  [MissionStatus.preAssigned]: '#fff',
  [MissionStatus.inProgress]: '#ffdc3354',
  [MissionStatus.validated]: '#EFFBF4',
  [MissionStatus.canceled]: '#8d8d8db8',
  [MissionStatus.deleted]: '#F0F3F8',
}

const rowColor: { [key in MissionStatus]: string } = {
  [MissionStatus.assigned]: '#000',
  [MissionStatus.preAssigned]: '#000',
  [MissionStatus.inProgress]: '#000',
  [MissionStatus.validated]: '#000',
  [MissionStatus.canceled]: '#000',
  [MissionStatus.deleted]: '#A3ADC2',

}

const statusColor: { [key in MissionStatus]: string } = {
  [MissionStatus.assigned]: '#FAB142',
  [MissionStatus.preAssigned]: '#FAB142',
  [MissionStatus.inProgress]: '#FAB142',
  [MissionStatus.validated]: '#43CF7B',
  [MissionStatus.canceled]: '#f44336',
  [MissionStatus.deleted]: '#8d8d8d',
}

const statusLabel: { [key in MissionStatus]: string } = {
  [MissionStatus.assigned]: 'Assignée',
  [MissionStatus.preAssigned]: 'Pré-assignée',
  [MissionStatus.inProgress]: 'En cours',
  [MissionStatus.validated]: 'Validée',
  [MissionStatus.canceled]: 'Refusée par le RD',
  [MissionStatus.deleted]: 'Supprimée',
}

export default function MissionsTable({
  technicalCenterId, selectedMissions, setSelectedMissions, missionsOrdering, setMissionsOrdering,
}: Props) {
  const dispatch = useAppDispatch()
  const { activeRole } = useAppSelector(state => state.app)
  const { agents, missionsTCLoading } = useAppSelector(state => state.missionTable)
  const { defects } = useAppSelector(state => state.params)
  const { getUserName } = useCerbereUsers(agents.map(agent => agent.cerbere_id), '-')

  if (missionsTCLoading && missionListSignal.value.length === 0) {
    return (
      <div className="flex-center mission-table loader">
        <Loader />
      </div>
    )
  }

  const handleStartMission = (missionId: string) => () => {
    modalSignal.value = (
      <ModalConfirm
        title="Êtes-vous sûr de vouloir démarrer cette mission ?"
        handleClose={() => { modalSignal.value = null }}
        handleValidate={handleStartMissionTC(technicalCenterId, missionId, () => {
          modalSignal.value = null
          dispatch(getMissionsTC({ technicenterId: technicalCenterId, ordering: missionsOrdering }))
        })}
      />
    )
  }

  const handleModifyMissionStart = (mission: MissionTC) => () => {
    modalSignal.value = (
      <ModalPatchMissionTCHour
        title="Mission prise en compte"
        handleClose={() => { modalSignal.value = null }}
        handleValidate={newValue => handlePatchMissionTC(
          technicalCenterId,
          mission.id,
          () => {
            modalSignal.value = null
            dispatch(getMissionsTC({ technicenterId: technicalCenterId, ordering: missionsOrdering }))
          },
          { prise_en_compte: newValue.format() },
        )}
        timeValue={moment(mission.prise_en_compte)}
      />
    )
  }

  const handleMissionPE = (missionId: string) => () => {
    modalSignal.value = (
      <ModalConfirm
        title="Êtes-vous sûr de vouloir valider la présence sur engin ?"
        handleClose={() => { modalSignal.value = null }}
        handleValidate={handleMissionTCPE(technicalCenterId, missionId, () => {
          modalSignal.value = null
          dispatch(getMissionsTC({ technicenterId: technicalCenterId, ordering: missionsOrdering }))
        })}
      />
    )
  }

  const handleModifyMissionPE = (mission: MissionTC) => () => {
    modalSignal.value = (
      <ModalPatchMissionTCHour
        title="Présence sur engin"
        handleClose={() => { modalSignal.value = null }}
        handleValidate={newValue => handlePatchMissionTC(
          technicalCenterId,
          mission.id,
          () => {
            modalSignal.value = null
            dispatch(getMissionsTC({ technicenterId: technicalCenterId, ordering: missionsOrdering }))
          },
          { presence_sur_engin: newValue.format() },
        )}
        timeValue={moment(mission.presence_sur_engin)}
      />
    )
  }

  const handleStartMissionNPD = (mission: MissionTC) => () => {
    modalSignal.value = (
      <ModalConfirm
        title="Êtes-vous sûr de vouloir débuter la phase de 'Ne pas déranger' ?"
        handleClose={() => { modalSignal.value = null }}
        handleValidate={handleMissionTCNPD(
          technicalCenterId,
          mission.id,
          () => {
            modalSignal.value = null
            dispatch(getMissionsTC({ technicenterId: technicalCenterId, ordering: missionsOrdering }))
          },
        )}
      />
    )
  }

  const handleModifyPatchNPD = (mission: MissionTC) => () => {
    modalSignal.value = (
      <ModalPatchMissionNPD
        handleClose={() => { modalSignal.value = null }}
        mission={mission}
        centerId={technicalCenterId}
        ordering={missionsOrdering}
      />
    )
  }

  const handleClickOrientation = (
    mission: MissionTC,
    sens: string,
    motrice: 'principale' | 'secondaire',
  ) => handlePatchMissionTC(
    technicalCenterId,
    mission.id,
    () => {
      dispatch(getMissionsTC({ technicenterId: technicalCenterId, ordering: missionsOrdering }))
    },
    { orientation_motrice: {
      ...mission.orientation_motrice,
      [motrice]: sens,
    } },
  )

  const handleSelectAllMissions = () => {
    if (missionListSignal.value.every(mission => selectedMissions.includes(mission.id))) {
      setSelectedMissions([])
    } else {
      setSelectedMissions(missionListSignal.value.map(mission => mission.id))
    }
  }

  const handleModifyObservation = (mission: MissionTC) => () => {
    modalSignal.value = (
      <ModalAddComment
        title="Ajouter un commentaire"
        handleClose={() => { modalSignal.value = null }}
        handleValidate={comment => handlePatchMissionTC(
          technicalCenterId,
          mission.id,
          () => {
            modalSignal.value = null
            dispatch(getMissionsTC({ technicenterId: technicalCenterId, ordering: missionsOrdering }))
          },
          { observation_com: comment },
        )}
      />
    )
  }

  return (
    <div className="mission-table">
      <div className="table-wrapper">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {Headers.map(header => (
                <TableCell
                  className="header-cell"
                  align="center"
                  key={header.id}
                  sx={{ color: 'white', border: '1px solid #3A4457', background: '#212731', fontSize: 12 }}
                >
                  {header.label}
                  {header.ordering && (
                    <div className="column-order">
                      <button
                        type="button"
                        onClick={() => {
                          const newOrdering = header.ordering
                          setMissionsOrdering(newOrdering)
                          dispatch(getMissionsTC({ technicenterId: technicalCenterId, ordering: newOrdering }))
                        }}
                      >
                        <KeyboardArrowUp
                          style={{ color: missionsOrdering === header.ordering ? '#256EFF' : '#fff' }}
                        />
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          const newOrdering = `-${header.ordering}`
                          setMissionsOrdering(newOrdering)
                          dispatch(getMissionsTC({ technicenterId: technicalCenterId, ordering: newOrdering }))
                        }}
                      >
                        <KeyboardArrowDown
                          style={{ color: missionsOrdering === `-${header.ordering}` ? '#256EFF' : '#fff' }}
                        />
                      </button>
                    </div>
                  )}
                </TableCell>
              ))}
              <TableCell
                align="center"
                sx={{ border: '1px solid #3A4457', background: '#212731', fontSize: 12, cursor: 'pointer' }}
                onClick={handleSelectAllMissions}
              >
                <Checkbox
                  checked={missionListSignal.value.every(mission => selectedMissions.includes(mission.id))}
                  indeterminate={missionListSignal.value.some(mission => selectedMissions.includes(mission.id))}
                  readonly
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ background: '#FFF' }}>
            {missionListSignal.value.map(row => (
              <TableRow
                key={row.id}
                sx={{
                  background: rowBGColor[row.statut],
                  color: rowColor[row.statut],
                }}
              >
                <TableCell component="th" scope="row">
                  <Chip label={row.numero} style={{ borderRadius: 4 }} color="success" />
                </TableCell>
                <TableCell align="center">
                  <div className="flex-column-center" style={{ gap: '12px' }}>
                    <span>{row.rame}</span>
                    <span>{row.um && `UM ${row.um_details}`}</span>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <MissionOrientation
                    technicalCenterId={technicalCenterId}
                    row={row}
                    handleClickOrientation={handleClickOrientation}
                    disabled={activeRole.role !== AppRoles.com || row.statut === MissionStatus.deleted}
                  />
                </TableCell>
                <TableCell align="center">{row.modele.libelle}</TableCell>
                <TableCell align="center">
                  {row.chantier_depart?.libelle || '-'}
                </TableCell>
                <TableCell align="center">
                  {row.voie_depart?.libelle || '-'}
                </TableCell>
                <TableCell align="center">
                  {row.voie_arrivee?.libelle || '-'}
                </TableCell>
                <TableCell align="center">
                  <div className="flex-center">
                    <span style={row.prioritaire ? {
                      color: '#fff',
                      background: '#DA4238',
                      borderRadius: '8px',
                      padding: '4px',
                      margin: '4px',
                    } : {}}
                    >
                      {moment(row.heure_debut_theorique).format('HH:mm')}
                    </span>
                    <DayGapBadge time={row.heure_debut_theorique} />
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className="flex-center">
                    {moment(row.heure_fin_theorique).format('HH:mm')}
                    <DayGapBadge time={row.heure_fin_theorique} />
                  </div>
                </TableCell>
                <TableCell align="center">
                  {getUserName(row.agent)}
                </TableCell>
                <TableCell align="center">
                  {row.sens_depart?.libelle || '-'}
                </TableCell>
                <TableCell align="center">
                  {row.via || '-'}
                </TableCell>
                <TableCell align="center">
                  <ActionWrapper
                    actions={[
                      {
                        title: 'Modifier',
                        available: [MissionStatus.inProgress, MissionStatus.validated].includes(row.statut)
                        && row.missions_supplementaires.length > 0 && activeRole.role === AppRoles.com,
                        onClick: () => {
                          modalSignal.value = (
                            <ModalPatchMissionSelectField
                              title="Missions supplémentaires"
                              handleClose={() => { modalSignal.value = null }}
                              handleValidate={newMissions => handlePatchAdditionnalMissionsTC(
                                technicalCenterId,
                                row.id,
                                row.missions_supplementaires.map(m => ({
                                  id: m.id,
                                  done: newMissions.map(ms => ms.id).includes(m.id),
                                })),
                                () => {
                                  modalSignal.value = null
                                  dispatch(getMissionsTC({
                                    technicenterId: technicalCenterId,
                                    ordering: missionsOrdering,
                                  }))
                                },
                              )}
                              options={row.missions_supplementaires.map(m => ({ id: m.id, libelle: m.modele.libelle }))}
                              selectedOptions={row.missions_supplementaires.filter(m => m.done).map(
                                m => ({ id: m.id, libelle: m.modele.libelle }),
                              )}
                            />
                          )
                        },
                      },
                    ]}
                  >
                    {row.missions_supplementaires.map(m => (
                      <span
                        key={m.id}
                        style={{ color: m.done ? '#008636' : '#000' }}
                      >
                        {m.modele.libelle}
                      </span>
                    ))}
                    {row.missions_supplementaires.length === 0 && '-'}
                  </ActionWrapper>
                </TableCell>
                <TableCell align="center">
                  <ActionWrapper
                    actions={[
                      {
                        title: 'Modifier',
                        available: activeRole.role === AppRoles.com,
                        onClick: handleModifyObservation(row),
                      },
                    ]}
                  >
                    {row.observation_com || '-'}
                  </ActionWrapper>
                </TableCell>
                <TableCell align="center">
                  {row.pancartage || '-'}
                </TableCell>
                <TableCell align="center">
                  <ActionWrapper
                    actions={[
                      {
                        title: 'Valider',
                        available: row.statut === MissionStatus.assigned && activeRole.role === AppRoles.com,
                        onClick: handleStartMission(row.id),
                      },
                      {
                        title: 'Modifier',
                        available: row.statut === MissionStatus.inProgress
                        && activeRole.role === AppRoles.com,
                        onClick: handleModifyMissionStart(row),
                      },
                    ]}
                  >
                    <div className="flex-center">
                      {row.prise_en_compte ? (
                        <div className="checkmark flex-column-center">
                          <Check />
                          <span>
                            {moment(row.prise_en_compte).format('HH[h]mm')}
                          </span>
                        </div>
                      ) : '-'}
                      <DayGapBadge time={row.prise_en_compte} />
                    </div>
                  </ActionWrapper>
                </TableCell>
                <TableCell align="center">
                  <ActionWrapper
                    actions={[
                      {
                        title: 'Valider',
                        available: row.statut === MissionStatus.inProgress && !row.presence_sur_engin
                        && activeRole.role === AppRoles.com,
                        onClick: handleMissionPE(row.id),
                      },
                      {
                        title: 'Modifier',
                        available: row.statut === MissionStatus.inProgress
                        && !!row.presence_sur_engin && activeRole.role === AppRoles.com,
                        onClick: handleModifyMissionPE(row),
                      },
                    ]}
                  >
                    <div className="flex-center">
                      {row.presence_sur_engin ? (
                        <div className="checkmark flex-column-center">
                          <Check />
                          <span>
                            {moment(row.presence_sur_engin).format('HH[h]mm')}
                          </span>
                        </div>
                      ) : '-'}
                      <DayGapBadge time={row.presence_sur_engin} />
                    </div>
                  </ActionWrapper>
                </TableCell>
                <TableCell align="center">
                  <ActionWrapper
                    actions={[
                      {
                        title: 'Débuter',
                        available: row.statut === MissionStatus.inProgress
                        && !row.debut_npd && !!row.presence_sur_engin && activeRole.role === AppRoles.com,
                        onClick: handleStartMissionNPD(row),
                      },
                      {
                        title: 'Modifier',
                        available: row.statut === MissionStatus.inProgress
                         && !!row.debut_npd && activeRole.role === AppRoles.com,
                        onClick: handleModifyPatchNPD(row),
                      },
                    ]}
                  >
                    {row.debut_npd ? (
                      <>
                        <div className="flex-center">
                          de:
                          {moment(row.debut_npd).format('HH[h]mm')}
                          <DayGapBadge time={row.debut_npd} />
                        </div>
                        <div className="flex-center">
                          à:
                          {row.fin_npd ? moment(row.fin_npd).format('HH[h]mm') : '-'}
                          {row.fin_npd && <DayGapBadge time={row.fin_npd} />}
                        </div>
                      </>
                    ) : '-'}
                  </ActionWrapper>
                </TableCell>
                <TableCell align="center">
                  <ActionWrapper
                    actions={[
                      {
                        title: 'Modifier',
                        available: [MissionStatus.inProgress, MissionStatus.validated].includes(row.statut)
                        && activeRole.role === AppRoles.com,
                        onClick: () => {
                          modalSignal.value = (
                            <ModalPatchMissionSelectField
                              title="Défauts constatés"
                              handleClose={() => { modalSignal.value = null }}
                              handleValidate={defauts => handlePatchMissionTC(
                                technicalCenterId,
                                row.id,
                                () => {
                                  modalSignal.value = null
                                  // eslint-disable-next-line max-len
                                  dispatch(getMissionsTC({ technicenterId: technicalCenterId, ordering: missionsOrdering }))
                                },
                                { defauts_rd: defauts.map(d => d.id as unknown as ParamsDefects) },
                              )}
                              options={defects}
                              selectedOptions={row.defauts_rd}
                            />
                          )
                        },
                      },
                    ]}
                  >
                    {row.defauts_rd.map(def => (
                      <span key={def.id}>{def.libelle}</span>
                    ))}
                    {row.commentaire_defauts_rd && (
                      <span>{row.commentaire_defauts_rd}</span>
                    )}
                    {row.defauts_rd.length === 0 && !row.commentaire_defauts_rd && '-'}
                  </ActionWrapper>
                </TableCell>
                <TableCell align="center">
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                  }}
                  >
                    {row.observations_rd.map(obs => (
                      <span key={obs.id}>{obs.libelle}</span>
                    ))}
                    {row.commentaire_observation_rd && (
                      <span>{row.commentaire_observation_rd}</span>
                    )}
                    {row.observations_rd.length === 0 && !row.commentaire_observation_rd && '-'}
                  </div>
                </TableCell>
                <TableCell align="center">
                  <Tooltip title={statusLabel[row.statut]}>
                    <CircleIcon sx={{ color: statusColor[row.statut] }} />
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <MissionActions
                    mission={row}
                    technicalCenterId={technicalCenterId}
                    disabled={activeRole.role !== AppRoles.com}
                    ordering={missionsOrdering}
                  />
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ padding: '16px !important', cursor: 'pointer' }}
                  onClick={() => {
                    if (selectedMissions.includes(row.id)) {
                      setSelectedMissions(selectedMissions.filter(id => id !== row.id))
                    } else {
                      setSelectedMissions([...selectedMissions, row.id])
                    }
                  }}
                >
                  <Checkbox
                    checked={selectedMissions.includes(row.id)}
                    readonly
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      {missionListSignal.value.length === 0 && (
        <span className="flex-center no-mission">
          Aucune Mission
        </span>
      )}
    </div>
  )
}
