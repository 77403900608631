import { Close } from '@mui/icons-material'
import { useState } from 'react'
import { TCFilter } from 'types'
import { useAppDispatch, useCerbereUsers } from 'utils'
import terms from 'assets/terms'
import { Button, ButtonStyle } from 'components'
import { getMissionsTC, patchTCFilters } from 'reducers/missionTable/thunks'
import Filter from './filter/Filter'
import './ModalMissionFilters.scss'

interface Props {
  technicenterId: string
  filters: TCFilter
  handleClose: () => void
  displayStatus: boolean
  ordering: string
}

export default function ModalMissionFilters({
  technicenterId, filters, handleClose, displayStatus, ordering,
}: Props) {
  const dispatch = useAppDispatch()
  const [newFilters, setNewFilters] = useState<TCFilter>(filters)
  const { getUserName } = useCerbereUsers()

  const handleResetFilters = () => {
    setNewFilters(filters)
  }

  const handleApplyFilters = () => {
    dispatch(patchTCFilters({
      technicenterId,
      filters: {
        agents_selected: newFilters.agents_selected,
        mission_modeles_selected: newFilters.mission_modeles_selected,
        statuts_selected: newFilters.statuts_selected,
      },
    })).then(() => {
      dispatch(getMissionsTC({ technicenterId, ordering }))
      handleClose()
    })
  }

  return (
    <div className="modal-mission-filter">
      <div className="header">
        <div className="title">
          <h2>Filtres</h2>
          <button type="button" className="flex-center">
            <Close onClick={handleClose} />
          </button>
        </div>
        <div className="recap">
          <span>
            {`Agent(s): ${newFilters.agents_selected.length === newFilters.agents.length
              ? 'Tout' : newFilters.agents_selected.map(agent => getUserName(agent)).join(', ')}`}
          </span>
          <span>
            {`Modèle(s): ${newFilters.mission_modeles_selected.length === newFilters.mission_modeles.length
              ? 'Tout' : newFilters.mission_modeles_selected.map(model => {
                const item = filters.mission_modeles.find(m => m.id === model)
                return item ? item.libelle : ''
              }).join(', ')}`}
          </span>
          {displayStatus && (
            <span>
              {`Statut(s): ${newFilters.statuts_selected.length === newFilters.statuts.length
                ? 'Tout' : newFilters.statuts_selected.map(status => terms.Common.missionStatus[status]).join(', ')}`}
            </span>
          )}
        </div>
      </div>
      <div className="filters">
        <Filter
          title="Agent(s)"
          handleClickAll={() => setNewFilters(prev => ({
            ...prev,
            agents_selected: prev.agents.length === prev.agents_selected.length
              ? [] : prev.agents.map(agent => agent.cerbere_id),
          }))}
          allSelected={newFilters.agents_selected.length === newFilters.agents.length}
          items={newFilters.agents}
          handleClick={agent => () => setNewFilters(prev => ({
            ...prev,
            agents_selected: prev.agents_selected.includes(agent.cerbere_id)
              ? prev.agents_selected.filter(id => id !== agent.cerbere_id)
              : [...prev.agents_selected, agent.cerbere_id],
          }))}
          itemSelected={agent => newFilters.agents_selected.includes(agent.cerbere_id)}
          getText={agent => getUserName(agent.cerbere_id)}
        />
        <Filter
          title="Mission(s)"
          handleClickAll={() => setNewFilters(prev => ({
            ...prev,
            mission_modeles_selected: prev.mission_modeles.length === prev.mission_modeles_selected.length
              ? [] : prev.mission_modeles.map(model => model.id),
          }))}
          allSelected={newFilters.mission_modeles_selected.length === newFilters.mission_modeles.length}
          items={newFilters.mission_modeles}
          handleClick={item => () => setNewFilters(prev => ({
            ...prev,
            mission_modeles_selected: prev.mission_modeles_selected.includes(item.id)
              ? prev.mission_modeles_selected.filter(id => id !== item.id)
              : [...prev.mission_modeles_selected, item.id],
          }))}
          itemSelected={item => newFilters.mission_modeles_selected.includes(item.id)}
          getText={item => item.libelle}
        />
        {displayStatus && (
          <Filter
            title="Statut(s)"
            handleClickAll={() => setNewFilters(prev => ({
              ...prev,
              statuts_selected: prev.statuts.length === prev.statuts_selected.length
                ? [] : prev.statuts.map(status => status.libelle),
            }))}
            allSelected={newFilters.statuts_selected.length === newFilters.statuts.length}
            items={newFilters.statuts.map(status => status.libelle)}
            handleClick={item => () => setNewFilters(prev => ({
              ...prev,
              statuts_selected: prev.statuts_selected.includes(item)
                ? prev.statuts_selected.filter(id => id !== item)
                : [...prev.statuts_selected, item],
            }))}
            itemSelected={item => newFilters.statuts_selected.includes(item)}
            getText={item => terms.Common.missionStatus[item]}
          />
        )}
      </div>
      <div className="actions">
        <Button
          text="Annuler les modifications"
          onClick={handleResetFilters}
          style={ButtonStyle.borderLess}
        />
        <Button text="Appliquer les filtres" onClick={handleApplyFilters} />
      </div>
    </div>
  )
}
