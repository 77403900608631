import { Box, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { Checkbox, CheckboxFormField, SelectFormField, TextFormField, TimeFormField } from 'components'
import terms from 'assets/terms'
import { useAppSelector, useCerbereUsers } from 'utils'
import { FormValues } from '../formHandlers'

interface Props {
  formValues: FormValues
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement | { name?: string | undefined; value: unknown }>) => void
  handleCheckboxChange: (name: string) => () => void
  handleTimeChange: (name: string, value: moment.Moment) => void
  handleMultiSelectChange: (e: SelectChangeEvent<string[]>) => void
  technicalCenterId: string
  requiredFields: string[]
  isInProgress: boolean
}

const localTerms = terms.Modals.AddMissionTC
export default function MissionTCFields({
  formValues, handleInputChange, handleCheckboxChange, handleTimeChange, handleMultiSelectChange,
  technicalCenterId, requiredFields, isInProgress,
}: Props) {
  const { agents } = useAppSelector(state => state.missionTable)
  const { missions, technicalCenters, additionalMissions } = useAppSelector(state => state.params)
  const { getUserName } = useCerbereUsers(agents.map(agent => agent.cerbere_id))

  const selectedChantierDepart = technicalCenters.find(tc => tc.id === technicalCenterId)?.chantiers.find(
    chantier => chantier.id === formValues.chantier_depart,
  )

  const selectedChantierArrivee = technicalCenters.find(tc => tc.id === technicalCenterId)?.chantiers.find(
    chantier => chantier.id === formValues.chantier_arrivee,
  )

  return (
    <div className="fields">
      {/* Rame */}
      <Box display="flex" flexDirection="column" gap="4px" marginBottom="-22px">
        <TextFormField
          value={formValues.rame}
          onChange={handleInputChange}
          label={localTerms.rame}
          required
          name="rame"
          disabled={isInProgress}
        />
        <CheckboxFormField
          value={formValues.um}
          onChange={handleCheckboxChange('um')}
          name={localTerms.um}
        />
      </Box>
      {/* Matériel attelé */}
      <TextFormField
        value={formValues.um_details}
        onChange={handleInputChange}
        label={localTerms.umDetails}
        disabled={!formValues.um}
        name="um_details"
      />
      {/* Mission */}
      <SelectFormField
        value={formValues.modele}
        onChange={handleInputChange}
        label={localTerms.mission}
        name="modele"
        options={missions.map(mission => ({ id: mission.id, libelle: mission.libelle }))}
        required
        disableUnselect
      />
      {/* Non visible pour le RD */}
      <CheckboxFormField
        value={formValues.prioritaire}
        onChange={handleCheckboxChange('prioritaire')}
        label={localTerms.rdHidden}
        name={localTerms.urgent}
      />
      {/* Chantier départ */}
      <SelectFormField
        value={formValues.chantier_depart}
        onChange={handleInputChange}
        label={localTerms.chantier_depart}
        name="chantier_depart"
        options={technicalCenters.find(tc => tc.id === technicalCenterId)?.chantiers.map(
          chantier => ({ id: chantier.id, libelle: chantier.libelle }),
        )}
        required={requiredFields.includes('chantier_depart')}
        disabled={!requiredFields.includes('chantier_depart')}
      />
      {/* Chantier arrivée */}
      <SelectFormField
        value={formValues.chantier_arrivee}
        onChange={handleInputChange}
        label={localTerms.chantier_arrivee}
        name="chantier_arrivee"
        options={technicalCenters.find(tc => tc.id === technicalCenterId)?.chantiers.map(
          chantier => ({ id: chantier.id, libelle: chantier.libelle }),
        )}
        required={requiredFields.includes('chantier_arrivee')}
        disabled={!requiredFields.includes('chantier_arrivee')}
      />
      {/* Voie de départ */}
      <SelectFormField
        value={formValues.voie_depart}
        onChange={handleInputChange}
        label={localTerms.voie_depart}
        name="voie_depart"
        options={selectedChantierDepart
          ? selectedChantierDepart.voies.map(voie => ({ id: voie.id, libelle: voie.libelle }))
          : technicalCenters.find(tc => tc.id === technicalCenterId)?.chantiers.flatMap(chantier => chantier.voies.map(
            voie => ({ id: voie.id, libelle: voie.libelle }),
          ))}
        required={requiredFields.includes('voie_depart')}
        disabled={!requiredFields.includes('voie_depart')}
      />
      {/* Voie d‘arrivée */}
      <SelectFormField
        value={formValues.voie_arrivee}
        onChange={handleInputChange}
        label={localTerms.voie_arrivee}
        name="voie_arrivee"
        options={selectedChantierArrivee
          ? selectedChantierArrivee.voies.map(voie => ({ id: voie.id, libelle: voie.libelle }))
          : technicalCenters.find(tc => tc.id === technicalCenterId)?.chantiers.flatMap(chantier => chantier.voies.map(
            voie => ({ id: voie.id, libelle: voie.libelle }),
          ))}
        required={requiredFields.includes('voie_arrivee')}
        disabled={!requiredFields.includes('voie_arrivee')}
      />
      {/* Heure début théorique */}
      <TimeFormField
        value={formValues.heure_debut_theorique}
        onChange={value => handleTimeChange('heure_debut_theorique', value)}
        label={localTerms.heure_debut_theorique}
        required
        disabled={formValues.modele === '' || isInProgress}
      />
      {/* Heure fin théorique */}
      <TimeFormField
        value={formValues.heure_fin_theorique}
        onChange={value => handleTimeChange('heure_fin_theorique', value)}
        label={localTerms.heure_fin_theorique}
        required
        disabled={formValues.modele === '' || isInProgress}
      />
      {/* Agent */}
      <SelectFormField
        value={formValues.agent}
        onChange={handleInputChange}
        label={localTerms.agent}
        name="agent"
        options={agents.filter(agent => agent.active).map(agent => ({ id: agent.cerbere_id,
          libelle: getUserName(agent.cerbere_id) }))}
        required
        disabled={formValues.modele === '' || isInProgress}
      />
      {/* Sens de départ */}
      <SelectFormField
        value={formValues.sens_depart}
        onChange={handleInputChange}
        label={localTerms.sens_depart}
        name="sens_depart"
        options={technicalCenters.find(tc => tc.id === technicalCenterId)?.sens_depart.map(
          depart => ({ id: depart.id, libelle: depart.libelle }),
        )}
        required={requiredFields.includes('sens_depart')}
        disabled={!requiredFields.includes('sens_depart')}
      />
      {/* Via */}
      <TextFormField
        value={formValues.via}
        onChange={handleInputChange}
        label={localTerms.via}
        required={requiredFields.includes('via')}
        name="via"
        disabled={!requiredFields.includes('via')}
      />
      {/* Missions supplémentaires */}
      <div className="select-form-field">

        <span>
          {localTerms.missions_supplementaires}
        </span>
        <Select
          multiple
          value={formValues.missions_supplementaires}
          onChange={handleMultiSelectChange}
          renderValue={values => values.map(id => additionalMissions
            .find(additional => additional.id === id)?.libelle).join(', ')}
          sx={{
            height: '48px',
            padding: 0,
            border: '1px solid #D7E1F3',

            '&:focus, &:hover': {
              border: '1px solid #256EFF',
            },

            '&.Mui-disabled': {
              background: '#F1F1F1',
            },

            '.MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
          disabled={formValues.modele === ''}
        >
          {additionalMissions.map(additional => (
            <MenuItem key={additional.id} value={additional.id}>
              <Checkbox readonly checked={formValues.missions_supplementaires.includes(additional.id)} />
              <ListItemText primary={additional.libelle} sx={{ marginLeft: 1 }} />
            </MenuItem>
          ))}
        </Select>
      </div>

      {/* Observation COM */}
      <TextFormField
        value={formValues.observation_com}
        onChange={handleInputChange}
        label={localTerms.observation_com}
        name="observation_com"
        disabled={formValues.modele === ''}
      />
      {/* Pancartage */}
      <TextFormField
        value={formValues.pancartage}
        onChange={handleInputChange}
        label={localTerms.pancartage}
        name="pancartage"
        disabled={formValues.modele === ''}
      />
    </div>
  )
}
