import { useEffect, useState } from 'react'
import * as moment from 'moment'
import { useAppSelector } from 'utils'
import { SelectChangeEvent } from '@mui/material'

export interface FormValues {
  rame: string;
  um: boolean;
  um_details: string;
  modele: string;
  prioritaire: boolean;
  chantier_depart: string;
  chantier_arrivee: string;
  voie_depart: string;
  voie_arrivee: string;
  heure_debut_theorique: moment.Moment;
  heure_fin_theorique: moment.Moment;
  agent: string;
  sens_depart: string;
  via: string;
  missions_supplementaires: string[];
  observation_com: string;
  pancartage: string;
  statut: string;
}

export const emptyFormValues: FormValues = {
  rame: '',
  um: false,
  um_details: '',
  modele: '',
  prioritaire: false,
  chantier_depart: '',
  chantier_arrivee: '',
  voie_depart: '',
  voie_arrivee: '',
  heure_debut_theorique: moment(),
  heure_fin_theorique: moment(),
  agent: '',
  sens_depart: '',
  via: '',
  missions_supplementaires: [],
  observation_com: '',
  pancartage: '',
  statut: 'PREAFFECTEE',
}

const useHandlers = (
  technicalCenterId: string,
  defaultValues?: FormValues,
) => {
  const [formValues, setFormValues] = useState<FormValues>(defaultValues || emptyFormValues)
  const { missions, technicalCenters } = useAppSelector(state => state.params)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement
| { name?: string | undefined; value: unknown }>) => {
    const { name, value } = e.target
    if (name === 'modele') {
      const selectedMission = missions.find(mission => mission.id === value)
      if (selectedMission) {
        setFormValues({
          ...formValues,
          modele: value as string,
          chantier_depart: '',
          chantier_arrivee: '',
          voie_depart: '',
          voie_arrivee: '',
          sens_depart: '',
          via: '',
          heure_debut_theorique: moment(),
          heure_fin_theorique: moment().add(selectedMission.duree_theorique, 'minutes'),
        })
      }
      return
    } if (name === 'voie_depart') {
      const selectedChantier = technicalCenters.find(tc => tc.id === technicalCenterId)?.chantiers.find(
        chantier => chantier.voies.find(voie => voie.id === value),
      )
      if (selectedChantier) {
        setFormValues({
          ...formValues,
          voie_depart: value as string,
          chantier_depart: selectedChantier.id,
        })
        return
      }
    } else if (name === 'voie_arrivee') {
      const selectedChantier = technicalCenters.find(tc => tc.id === technicalCenterId)?.chantiers.find(
        chantier => chantier.voies.find(voie => voie.id === value),
      )
      if (selectedChantier) {
        setFormValues({
          ...formValues,
          voie_arrivee: value as string,
          chantier_arrivee: selectedChantier.id,
        })
        return
      }
    } else if (name === 'chantier_depart') {
      setFormValues({
        ...formValues,
        chantier_depart: value as string,
        voie_depart: '',
      })
      return
    } else if (name === 'chantier_arrivee') {
      setFormValues({
        ...formValues,
        chantier_arrivee: value as string,
        voie_arrivee: '',
      })
      return
    }
    setFormValues({
      ...formValues,
      [name as string]: value,
    })
  }

  const handleCheckboxChange = (name: string) => () => {
    if (name === 'um' && formValues.um) {
      setFormValues({
        ...formValues,
        um: false,
        um_details: '',
      })
    } else {
      setFormValues({
        ...formValues,
        [name]: !formValues[name as keyof FormValues],
      })
    }
  }

  const handleTimeChange = (name: string, value: moment.Moment) => {
    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  const handleMultiSelectChange = (e: SelectChangeEvent<string[]>) => {
    const selectedValue = e.target.value
    if (typeof selectedValue === 'string') return
    setFormValues({
      ...formValues,
      missions_supplementaires: selectedValue,
    })
  }

  return {
    formValues,
    handleInputChange,
    handleCheckboxChange,
    handleTimeChange,
    handleMultiSelectChange,
  }
}

const initialRequiredFields = ['rame', 'modele', 'heure_debut_theorique', 'heure_fin_theorique']
const useRequiredFields = (formValues: FormValues) => {
  const [requiredFields, setRequiredFields] = useState(initialRequiredFields)
  const { missions } = useAppSelector(state => state.params)

  // --- Change required fields based on chosen mission modele ---
  useEffect(() => {
    const selectedMission = missions.find(mission => mission.id === formValues.modele)
    if (selectedMission) {
      setRequiredFields([
        ...initialRequiredFields,
        ...(Object.entries(selectedMission).filter(([, value]) => value === true).map(([key]) => key)),
      ])
    }
  }, [formValues.modele, missions])
  // -------

  return requiredFields
}

export {
  useHandlers,
  useRequiredFields,
}
